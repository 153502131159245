import { Button, Image } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const HomeAbout = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white dark:bg-slate-700 py-10">
      <div className="w-full flex relative gap-2">
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-black/50 z-[999] flex flex-col gap-5 items-start justify-center px-10 md:px-32">
          <h2 className="text-white text-2xl font-semibold">
            Textaj Sourcing International
          </h2>

          <span className="text-sm text-white -mt-5">
            Sustainable Apparel Solution
          </span>
          <Button
            type="primary"
            onClick={() => navigate("/about-us", { replace: true })}
          >
            About Us
          </Button>
        </div>
        <div className="hidden md:flex flex-1">
          <Image
            className="w-full"
            preview={false}
            src="/assets/images/about/1.jpg"
            alt="company image"
            height={450}
            width={"100%"}
          />
        </div>
        <div className="hidden sm:flex flex-1 items-center justify-center h-full  text-center">
          <Image
            className="w-full"
            preview={false}
            src="/assets/images/about/2.jpg"
            alt="company image"
            height={450}
            width={"100%"}
          />
        </div>
        <div className=" hidden lg:flex flex-1">
          <Image
            className="w-full"
            preview={false}
            src="/assets/images/about/3.jpg"
            alt="company image"
            height={450}
            width={"100%"}
          />
        </div>
        <div className="flex-1">
          <Image
            className="w-full"
            preview={false}
            src="/assets/images/new/4.jpg"
            alt="company image"
            height={450}
            width={"100%"}
          />
        </div>
        <div className="flex-1">
          <Image
            className="w-full"
            preview={false}
            src="/assets/images/new/5.jpg"
            alt="company image"
            height={450}
            width={"100%"}
          />
        </div>
      </div>
      {/* <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-5"> */}
      {/* <div className="flex items-center justify-center">
          <Image
            preview={false}
            src="/assets/business.svg"
            alt="company image"
            height={350}
          />
        </div> */}
      {/* <div className="container mx-auto  grid grid-cols-1 md:grid-cols-2 gap-10 my-10">
        <div className="flex flex-col items-start gap-5">
          <h2 className="font-bold text-5xl text-slate-700 dark:text-white text-left">
            Textaj Sourcing International
          </h2>
          <h2 className="font-bold text-sm text-slate-900 dark:text-white text-center uppercase -mt-2">
            Sustainable Apparel Solution
          </h2>
          <div className="mt-5 flex flex-col gap-5">
            <p className="text-base">
              <span className="font-bold text-xl">Overview:</span>
              &nbsp;Founded in 2020, Textaj Sourcing International is a premier
              apparel sourcing and manufacturing agent based in Bangladesh.
              Specializing in sustainable fashion, the company has rapidly
              established itself as a reliable partner for clients around the
              globe, including Denmark, France, Italy, the USA, the UK,
              Australia, and various Scandinavian countries.
            </p>
            <p className="text-base">
              <span className="font-bold text-xl">
                Commitment to Sustainability:
              </span>
              &nbsp; Textaj Sourcing International prides itself on offering
              eco-friendly products that do not compromise on style. The
              company&#39;s product range includes apparel made from BCI (Better
              Cotton Initiative) cotton, organic materials, and recycled fibers.
              This commitment to sustainability ensures that each piece of
              clothing is not only fashionable but also environmentally
              responsible.
            </p>
            <p className="text-base">
              <span className="font-bold text-xl">Mission: </span>&nbsp;Textaj
              Sourcing International’s mission is to provide sustainable and
              eco-friendly products that meet the fashion needs of their clients
              while protecting the environment. This dual focus on style and
              sustainability sets the company apart in the competitive of
              apparel manufacturing.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Image
            preview={false}
            src="/assets/business_deal.svg"
            alt="company image"
            height={350}
          />
        </div> 
      </div>*/}
      {/* </div> */}
    </div>
  );
};

export default HomeAbout;
